<template>
  <section class="home d-flex align-items-center">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-text">
            <h1>IDIK</h1>
            <p>
              INSTALASI DIAGNOSTIC INTERVERSI KARDIOVASKULER (IDIK) /
              Angiography Adalah Tindakan/Prosedur Diagnostik Invasif
              Menggunakan Sinar X (X-Ray) Untuk Menggambarkan Pembuluh Darah
              Diberbagai Bagian Tubuh Termasuk Jantung, Otak Dan Ginjal Untuk
              Melihat Apakah Ada Penyakit, Penyempitan, Pelebaran Atau
              Penyumbatan Pada Pembuluh Darah.
            </p>
            <br />
            <p style="font-weight: bold">Kegunaan dari IDIK & Angiography :</p>
            <p>
              1. Mendeteksi penyakit artherosclerosis pada arteri carotis di
              leher, yang menggangu aliran darah ke otak dan bahkan dapat
              menyebabkan stroke.
            </p>
            <p>
              2. Mengidentifikasikan aneurysma intractranial atau kelainan lain
              pada pembuluh darah di otak.
            </p>
            <p>
              3. Mengevaluasi penyakit di pembuluh darah ginjal atau membantu
              mempersiapkan transplantasi ginjal.
            </p>
            <p>4. Mendeteksi aneurysma pembuluh darah aorta.</p>
            <p>
              5. Mengidentifikasi sumber pendarahan seperti pada dinding usus.
            </p>
            <p>
              6. Membantu menyiapkan operasi penyakit pembuluh darah pada
              tungkai bawah pasien yang mempunyai keluhan pada waktu berjalan.
            </p>
            <p>
              7. Memperlihatkan artherosclerosis pada pembuluh darah jantung.
            </p>
            <br />
            <p style="font-weight: bold">
              Keuntungan dari IDIK / Angiography :
            </p>

            <p>
              1. Kateter angiography dapat menampilkan gambar pembuluh darah
              secara detil, jelas dan akurat. Sangat membantu dalam tindakan
              prosedur operasi atau Percutaneous Transluminal Coronary
              Angioplasty (PTCA).
            </p>

            <p>
              2. Tidak seperti CT Angiography atau MR Angiography, menggunakan
              kateter yang memungkinkan untuk mengkombinasikan diagnosa dan
              tindakan dalam satu prosedur, misalnya : menemukan daerah
              penyempitan arteri diikuti dengan angioplasty dan penempatan
              stent.
            </p>

            <p>
              3. Kateter angiography dapat menampilkan gambaran pembuluh darah
              secara detil yang tidak bisa dihasilkan oleh prosedur noninvasive.
            </p>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <img :src="require('../../assets/layanan/idik.jpg')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  created() {
    document.title = "Idik RSUD GAMBIRAN KOTA KEDIRI";
  },
};
</script>